document.addEventListener('DOMContentLoaded', () => {
    const appHeight = () => {
        const doc = document.documentElement;
        doc.style.setProperty('--app-height', `${window.innerHeight}px`);
    };

    appHeight();

    window.addEventListener('resize', appHeight); // browser code
});
